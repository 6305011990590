import {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import useDataPost from "../../hooks/useDataPost";
import useAuth from "../../hooks/useAuth";
import {SocietyDTO} from "../../context/SocietyProvider";
import useSociety from "../../hooks/useSociety";
import PageToDelete from "../../pages/pageToDelete/PageToDelete";
import SocietyNotFound from "../societyNotFound/SocietyNotFound";

interface SocietyWrapperProps {
    subdomain: string
}

const SocietyWrapper = ({subdomain}: SocietyWrapperProps) => {
    const [isProcessing, setIsProcessing] = useState(true);
    const [error, setError] = useState<any>(null);
    const {society, setSociety} = useSociety();
    const {auth} = useAuth();
    const isTokenPresent = !!auth.token;
    const {postDataPromise} = useDataPost<SocietyDTO>('', {}, isTokenPresent, false);

    useEffect(() => {
        let isMounted = true;

        const getSociety = async () => {
            postDataPromise('/societies/by-subdomain', {subdomain})
                .then(response => {
                    setSociety(response.data)
                }).catch(error => {
                setError(error)
            }).finally(() => {
                isMounted && setIsProcessing(false);
            })
        }

        Object.keys(society).length === 0 && !error && getSociety()

        return () => {
            isMounted = false;
        }

    }, [subdomain, error]);

    return (
        <>
            {isProcessing
                ? <p> Loading ... </p>
                : error ? <SocietyNotFound/> : <Outlet/>
            }
        </>
    )
}

export default SocietyWrapper;