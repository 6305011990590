import React from "react";
import SocietiesList from "../../components/societiesList/SocietiesList";
import About from "../../components/about/About";
import Contact from "../../components/contact/Contact";
import GlobalSearch from "../../components/globalSearch/GlobalSearch";

const Homepage = () => {

    return (
        <div>
            <GlobalSearch/>
            <SocietiesList/>
            <About/>
            <Contact/>
        </div>
    );
}

export default Homepage