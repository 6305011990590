import {createContext, Dispatch, ReactNode, SetStateAction, useState} from "react";

export interface SocietyDTO {
    id?: number,
    name?: string,
    enabled?: boolean,
    visible?: boolean,
}

interface SocietyContextInterface {
    society: SocietyDTO
    setSociety: Dispatch<SetStateAction<SocietyDTO>>
}

const SocietyContext = createContext<SocietyContextInterface>({
    society: {},
    setSociety: () => {
    }
});

interface SocietyProviderProps {
    children: ReactNode
}

export const SocietyProvider = ({children}: SocietyProviderProps) => {
    const [society, setSociety] = useState<SocietyDTO>({});

    return (
        <SocietyContext.Provider value={{
            society,
            setSociety
        }}>
            {children}
        </SocietyContext.Provider>
    )
}

export default SocietyContext;