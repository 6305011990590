import React from "react";

const GlobalSearch = () => {

    return (
        <div style={{border: '1px solid black'}}>
            <h1>Looty</h1>
            <p>
                Découvre des sociétés près de chez toi, commande directement leurs produits en ligne, informe-toi sur
                leurs manifestations... et bien plus encore !
            </p>
            <input style={{width: '200px'}} type="text" placeholder="Produit, société, manifestation, ..."/>
        </div>
    );
}

export default GlobalSearch