import {Outlet} from "react-router-dom";
import React, {useState} from "react";
import useLogin from "../../hooks/useLogin";
import useLogout from "../../hooks/useLogout";

const SocietyWrapper = () => {

    const [emailLogin, setEmailLogin] = useState("");
    const [passwordLogin, setPasswordLogin] = useState("");

    const {login, error} = useLogin();
    const logout = useLogout();

    const authenticate = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        login(emailLogin, passwordLogin);
    }

    const disconnect = async () => {
        logout();
    }

    return (
        <>
            <div className="header" style={{border: '1px solid black'}}>
                <div>
                    <form onSubmit={(e) => authenticate(e)}>
                        <input type="email" placeholder="Email"
                               onChange={(e) => setEmailLogin(e.target.value)} required/>
                        <input type="password" placeholder="Mot de passe"
                               onChange={(e) => setPasswordLogin(e.target.value)} required/>
                        <button>Se connecter</button>
                    </form>
                    <button onClick={disconnect}>Se déconnecter</button>
                </div>
            </div>
            <Outlet/>
            <div className="footer" style={{border: '1px solid black'}}>
                <div>
                    <p>Looty tous droits réservés</p>
                </div>
            </div>
        </>
    )
}

export default SocietyWrapper;