import React from "react";
import useDataFetch from "../../hooks/useDataFetch";

const SocietiesList = () => {

    const {data: subdomainsAvailable} = useDataFetch(
        `/societies`, false
    );

    return (
        <div style={{border: '1px solid black'}}>
            <h2>Sociétés</h2>
            <p>
                Qu’elles vendent des produits artisanaux, offrent leurs services sur rendez-vous, organisent des
                manifestations, ou veuillent tout simplement se faire connaître, les sociétés de ta région sont sur
                Looty. N’attends plus pour les découvrir.
            </p>
            <ul>
                {subdomainsAvailable && subdomainsAvailable.length === 0 && (
                    <p>Aucune société n'est encore inscrite, soyez la première !</p>
                )}
                {subdomainsAvailable?.map((society: any) =>
                    <li key={society}>
                        <a href={`http://${society}.looty.ch`}>{society}</a>
                    </li>
                )}
            </ul>
        </div>
    );
}

export default SocietiesList