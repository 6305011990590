import React, {useState} from "react";
import useDataPost from "../../hooks/useDataPost";

interface ContactMessageDTO {
    firstname: string,
    lastname: string,
    society?: string,
    email: string,
    phoneNumber?: string,
    message: string
}

const Contact = () => {

    const [firstname, setFirstname] = useState<string>('');
    const [lastname, setLastname] = useState<string>('');
    const [society, setSociety] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const {postData} = useDataPost('', {});

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        postData('/contact', {
            firstname,
            lastname,
            society,
            email,
            phoneNumber,
            message
        });
    }

    return (
        <div style={{border: '1px solid black'}}>
            <h2>Contact</h2>

            <p>
                Une question ? Vous souhaitez une démo du produit ? Que vous soyez une société ou un client de la
                plateforme, n’hésitez pas à nous contacter et nous reviendrons vers vous dans les 48 heures.
            </p>

            <form onSubmit={(e) => submit(e)}>
                <input type="text" placeholder="Prénom"
                       onChange={(e) => setFirstname(e.target.value)} required/>
                <input type="text" placeholder="Nom"
                       onChange={(e) => setLastname(e.target.value)} required/>
                <input type="text" placeholder="Société (facultatif)"
                       onChange={(e) => setSociety(e.target.value)}/>
                <input type="text" placeholder="Email"
                       onChange={(e) => setEmail(e.target.value)}/>
                <input type="text" placeholder="N° téléphone (facultatif)"
                       onChange={(e) => setPhoneNumber(e.target.value)}/>
                <textarea placeholder="Votre message"
                          onChange={(e) => setMessage(e.target.value)} required/>
                <button>Envoyer</button>
            </form>
        </div>
    );
}

export default Contact