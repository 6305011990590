import React, {useEffect, useState} from "react";
import useSociety from "../../../hooks/useSociety";
import useDataPut from "../../../hooks/useDataPut";
import {SocietyDTO} from "../../../context/SocietyProvider";

const SocietyAdmin = () => {

    const {society, setSociety} = useSociety();
    const [societyName, setSocietyName] = useState<string>(society.name || '');

    const {data, error, isLoading, putData} = useDataPut<SocietyDTO>('', {}, true, false);

    const updateSociety = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await putData(`/societies/${society.id}`, {id: society.id, name: societyName});
    }

    useEffect(() => {
        data && setSociety(data)
    }, [data]);

    return (
        <div>
            <h1>Page d'admin de {society.name}</h1>
            <form onSubmit={(e) => updateSociety(e)}>
                <input type="text" placeholder="Nom de la société"
                       onChange={(e) => setSocietyName(e.target.value)} required/>
                <button>Mettre à jour la société</button>
            </form>
            <br></br>
        </div>
    );
}

export default SocietyAdmin