import React from "react";
import {useNavigate} from "react-router-dom";

const About = () => {

    const navigate = useNavigate();

    return (
        <div style={{border: '1px solid black'}}>
            <h2>À Propos</h2>
            <p>
                Looty a été fondé en 2024 afin de répondre aux besoins des petites sociétés souhaitant, entre autres,
                vendre leurs produits en lignes et organiser des manifestations. Les trois fondateurs ont su mettre en
                commun leur expertise respective dans la gestion de sociétés, l’organisation d’événements et le
                développement informatique pour vous proposer une plateforme aussi simple d’utilisation que possible et
                qui vous permette de faire connaître votre activité au plus grand nombre. Le concept vous intéresse ?
                Alors n’attendez plus et enregistrez votre société en 2 minutes.
            </p>
            <button onClick={() => navigate('/register')}>Inscrire ma société</button>
            <br></br>
            <br></br>
            <br></br>
        </div>
    );
}

export default About