import React, {useState} from "react";
import useDataPost from "../../hooks/useDataPost";

const Register = () => {

    const [emailRegistration, setEmailRegistration] = useState("");
    const [passwordRegistration, setPasswordRegistration] = useState("");
    const [societyNameRegistration, setSocietyNameRegistration] = useState("");
    const [subdomainRegistration, setSubdomainRegistration] = useState("");


    const {postData} = useDataPost("/auth/register", {
        email: emailRegistration,
        password: passwordRegistration,
        societyName: societyNameRegistration,
        societySubdomain: subdomainRegistration
    }, false);

    const register = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        postData();
    }

    return (
        <div>
            <h1>Page d'inscription</h1>
            <form onSubmit={(e) => register(e)}>
                <input type="email" placeholder="Email"
                       onChange={(e) => setEmailRegistration(e.target.value)} required/>
                <input type="text" placeholder="Society name"
                       onChange={(e) => setSocietyNameRegistration(e.target.value)} required/>
                <input type="text" placeholder="Subdomain"
                       onChange={(e) => setSubdomainRegistration(e.target.value)} required/>
                <input type="password" placeholder="Mot de passe"
                       onChange={(e) => setPasswordRegistration(e.target.value)} required/>
                <button>S'inscrire</button>
            </form>
            <br></br>
        </div>
    );
}

export default Register