import React from "react";
import useSociety from "../../../hooks/useSociety";

const SocietyHomepage = () => {

    const {society} = useSociety();


    return (
        (society && society.enabled && society.visible) ? (
            <div>
                Nom de la société: {society.name}
            </div>
        ) : (
            <div>
                <p>Cette société n'est pas accessible actuellement.</p>
                <p>Si vous en êtes le propriétéaire, veuillez vous connecter pour la gérer.</p>
            </div>
        )
    );
}

export default SocietyHomepage